@import "./fonts.css";
@import "./keyframes.css";
@import "./utilities.css";
@import "./nprogress.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@-ms-viewport {
  width: device-width;
  height: device-width;
}

@layer base {
  :root {
    /* Spacing */
    --site-padding-mobile: 0.75rem;
    --site-padding-laptop: 1.5rem;
    --site-padding-mobile-full: calc(var(--site-padding-mobile) * 2);
    --site-padding-laptop-full: calc(var(--site-padding-laptop) * 2);
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .text-animation {
    color: var(--color-black);
    text-shadow: -2px 2px 0 #eeede4, 2px 2px 0 #eeede4, 2px -2px 0 #eeede4,
      -2px -2px 0 #eeede4;
    mix-blend-mode: normal;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    @apply absolute w-full text-center text-[19vw] font-bold uppercase leading-[.85];
    /* Temp fix for flicker issue */
    transition: transform 0.3s ease;
  }
}

@layer utilities {
  /* Semi transparent scrollbar styling  */
  /* Chrome, Edge, and Safari */
  .scrollbar-semi-transparent::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .scrollbar-semi-transparent::-webkit-scrollbar-track {
    /* We use a dark gray default set at 0 opacity because some browsers don't support transparency so we should show a dark track https://jira.nike.com/browse/NVS-3249 */
    background: rgba(17, 17, 17, 0);
  }
  .scrollbar-semi-transparent::-webkit-scrollbar-thumb {
    background: rgba(238, 237, 228, 0.25);
    border-radius: 8px;
  }
  /* Firefox */
  .scrollbar-semi-transparent {
    scrollbar-width: thin;
    scrollbar-color: rgba(238, 237, 228, 0.25) transparent; /* scroll thumb and track */
  }

  .bg-grid {
    @apply bg-repeat;
    background-position: 0 0;
    background-size: 12px 12px;
  }
  @screen md {
    .bg-grid {
      background-size: 24px 24px;
    }
  }
  .bg-grid-dark {
    background-image: linear-gradient(to right, #222222 1px, transparent 1px),
      linear-gradient(to bottom, #222222 1px, transparent 1px);
  }
  .bg-grid-light {
    background-image: linear-gradient(to right, #e5e5e5 1px, transparent 1px),
      linear-gradient(to bottom, #e5e5e5 1px, transparent 1px);
  }
  .bg-grid-overlay {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.025) 1px,
        transparent 1px
      ),
      linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.025) 1px,
        transparent 1px
      );
  }
  /* Gradient border - See <Card> for usage. */
  .special-border-gradient {
    border-image-slice: 1;
    border-image-source: linear-gradient(to top left, #b7ff2c, #3369ff);
  }
  /* For nested items that need to inherit border image style */
  .special-border-inherit {
    border-color: inherit;
    border-image-source: inherit;
    border-image-slice: inherit;
  }
}

html,
body,
div#__next {
  height: 100%;
  width: 100%;
  min-height: 100%;
}

body {
  font-family: var(--font-helvetica), "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

/* Lock scroll for iOS */
.lock-scroll {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
